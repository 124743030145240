<template>
    <div v-if="hasOwnerId">

  <b-table-simple responsive >
    <thead>
      <tr class="tr-header">
        <th v-for="(header, index) in headers" :key="index">
          {{ header }}
        </th>
      </tr>
    </thead>

    <tbody v-for="exam in examsList" :key="exam.id">
      <tr class="tr-tbody">
        <td>
          <div>
            {{ moment(exam?.start_datetime).format('HH[h]mm') }}
          </div>
          <div class="exam-date">
            {{ moment(exam?.start_datetime).format('DD/MM/YYYY') }}
          </div>
        </td>

        <td>
          <div class="patient-container">
            <PatientInformation
              :appointment="exam"
              :status="getExamsStatus(exam)"
            />
            <Export
              v-can="'FpPac1'"
              @click="goPatient(exam?.patient?.id)"
              class="export"
            />
          </div>
        </td>
        <td>
          <Ellipsis>
            {{ exam?.professional?.name ?? '-' }}
          </Ellipsis>
        </td>
        <td>
          <Ellipsis>
            {{ exam?.room?.name ?? '-' }}
          </Ellipsis>
        </td>
        <td
          class="procedure-column pending"
          :class="{
            'not-finished-exams': getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED'
          }"
        >
          <div v-if="getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED'">
            <div>
              {{
                textualExamCounting(
                  getAppointmentItemsCountingByStatus(exam, 'PENDING', 'EXAM')
                )
              }}
            </div>
            <div>
              {{
                textualProcedureCounting(
                  getAppointmentItemsCountingByStatus(
                    exam,
                    'OPENED',
                    'PROCEDURE'
                  )
                )
              }}
            </div>
          </div>
          <span v-else>0</span>
        </td>
        <td
          class="procedure-column finished"
          :class="{
            'not-finished-exams': getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED'
          }"
        >
          <div
            v-if="
              getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'EXAM') >
                0 ||
              getAppointmentItemsCountingByStatus(
                exam,
                'FINISHED',
                'PROCEDURE'
              ) > 0
            "
          >
          <div>
              {{
                textualExamCounting(
                  getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'EXAM')
                )
              }}
            </div>
            <div>
              {{
                textualProcedureCounting(
                  getAppointmentItemsCountingByStatus(
                    exam,
                    'FINISHED',
                    'PROCEDURE'
                  )
                )
              }}
            </div>
          </div>
          <span v-else> 0 </span>
        </td>
        <td></td>
        <td>
          <div class="actions">
            <b-button
              v-b-toggle="`collapse-${exam?.id}-${exam?.id}`"
              class="toggle-btn"
              @click="rotateIcon(exam?.id)"
            >
              <ChevronDown
                class="arrow"
                :class="rotatedList.includes(exam?.id) ? 'rotated' : ''"
              />
            </b-button>
          </div>
        </td>
      </tr>

      <tr class="collapsable-row">
        <td :colspan="14">
          <b-collapse :id="`collapse-${exam?.id}-${exam?.id}`" class="collapse">
            <SubLabpacsTable
              :exam="exam"
              @reload="() => $emit('reload')"
              @open-annotation-modal="handleOpenAnnotationModal"
              @open-show-file-modal="handleOpenShowFileModal"
              @request-report="requestReport"
            />
          </b-collapse>
        </td>
      </tr>
    </tbody>
  </b-table-simple>

  <AnnotationModal
      :labpacsItem="labpacsItemSelected"
      @close="closeAnnotationModal"
      ref="annotationModal"
      />


    <ShowFileModal
      :labpacsItem="labpacsItemSelected"
      :patientProp="patientSelected"
      :itemId="itemIdSelected"
      @close="closeShowFileModal"
      @request-report="requestReport"
      ref="showFileModal"
    />
      
    </div>
  <NoFilterResults
    v-else-if="!examsList.length && !isLoading && hasOwnerId"
    message="Os filtros aplicados não geraram nenhum resultado"
  />
  <NeedOwnerView v-else-if="!isLoading && !hasOwnerId" />
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import api from '@/modules/exams/api'

export default {
  name: 'LabpacsTable',
  components: {
    PatientInformation: () =>
      import('@/components/Overview/ScheduleTablePatientInformation'),
    Export: () => import('@/assets/icons/export.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    SubLabpacsTable: () => import('@/components/Exam/SubLabpacsTable'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    NeedOwnerView: () => import('@/components/Exam/NeedOwnerView'),
    AnnotationModal: () => import('@/components/Exam/AnnotationModal'),
    ShowFileModal: () => import('@/components/Exam/ShowFileModal'),
    NoFilterResults: () => import('@/components/General/noFilterResults')

  },
  props: {
    examsList: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    selected: [],
    clinic: getCurrentClinic(),
    user: getCurrentUser(),
    headers: [
      'Horário',
      'Paciente',
      'Profissional',
      'Sala',
      'Pendentes',
      'Realizados',
      ''
    ],
    invalidDate: '-000001-11-30T03:06:28.000000Z',
    rotatedList: [],
    examsStatusCount: {},
    ownerNumber: null,
    labpacsClinicsId: null,
    isLoading: null,
    labpacsItemSelected: null,
    patientSelected: null,
    itemIdSelected: null
  }),
  computed: {
    hasOwnerId() {
      return this.ownerNumber
    }
  },
  async mounted() {
    await this.getOwnerNumber()
  },
  methods: {
    rotateIcon(id) {
      this.rotatedList.includes(id)
        ? this.rotatedList.pop(id)
        : this.rotatedList.push(id)
    },
    goPatient(patientId) {
      const patientRoute = this.$router.resolve(
        `/pacientes/${patientId}`
      )
      window.open(patientRoute.href, '_blank')
    },

    aggregated(examStatusCount) {
      this.examsStatusCount[examStatusCount[0]] = examStatusCount[1]
    },

    getAppointmentItemsCountingLabpacsItemsPending(appointment, type = 'EXAM') {
      return appointment.appointment_items.reduce((ac, it) => {
        if (
          !it.labpacs_item?.study_instance_uid &&
          it.item.type === type
        ) {
          ac++
        }

        return ac
      }, 0)
    },

    getAppointmentItemsCountingByItemsFinished(appointment, type = 'EXAM') {
      return appointment.appointment_items.reduce((ac, it) => {
        if (
          it.labpacs_item?.study_instance_uid &&
          it.item.type === type
        ) {
          ac++
        }

        return ac
      }, 0)
    },

    /**
     * Count appointment items by their status
     * @param {Object} appointment
     * @param {String} status
     * @returns {Number}
     */
    getAppointmentItemsCountingByStatus(appointment, status, type = 'EXAM') {
      return appointment.appointment_items.reduce((ac, it) => {
        if (
          it.attendance &&
          it.attendance.status === status &&
          it.item.type === type
        ) {
          ac++
        }
        if (
          !it.attendance &&
          (status === 'PENDING' || status === 'OPENED') &&
          it.item.type === type
        ) {
          ac++
        }

        return ac
      }, 0)
    },
    /**
     * "Workaround" to get finished exams status
     * @param {Object} exam
     * @returns {string}
     */
    getExamsStatus(exam) {
      const finished =
        this.getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'EXAM') +
        this.getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'PROCEDURE')

      return finished === exam.appointment_items.length
        ? 'ALL_EXAMS_FINISHED'
        : exam.status
    },
    /**
     * @param {Number} examsCount
     * @returns {String|Number}
     */
    textualExamCounting(examsCount) {
      return examsCount > 0
        ? examsCount > 1
          ? `${examsCount} Exames`
          : `${examsCount} Exame`
        : ''
    },
    textualProcedureCounting(proceduresCount) {
      return proceduresCount > 0
        ? proceduresCount > 1
          ? `${proceduresCount} Procedimentos`
          : `${proceduresCount} Procedimento`
        : ''
    },
    async getOwnerNumber() {
      const isLoading = this.$loading.show()
      this.isLoading = true
      try {
          const clinicId = this.clinic.id
          const response = await this.api.getLabpacsClinicsConfigByClinic(clinicId)
          if (response.data) {
              this.ownerNumber = response.data.owner_id
              this.labpacsClinicsId = response.data.id
          }
      } catch (error) {
          console.error(error)
          this.$toast.error('Erro ao obter Owner Id. Tente novamente mais tarde ou entre em contato com o suporte.')
          throw error
      } finally {
          isLoading.hide()
          this.isLoading = false
      }
    },
    handleOpenAnnotationModal(labpacsItem) {
      this.labpacsItemSelected = labpacsItem
      this.$bvModal.show('annotation-modal')
    },
    closeAnnotationModal() {
      this.labpacsItemSelected = null
      this.$bvModal.hide('annotation-modal')
    },
    async handleOpenShowFileModal(data) {
      const isLoading = this.$loading.show()
      try {
        this.itemIdSelected = data.item_id
        this.patientSelected = data.patient
        this.labpacsItemSelected = data.labpacsItemSelected
        const response = await this.api.retrieveFromWorklist({ id: data.labpacsItem.id, medical_record_number: data.patient.medical_record_number})

        if (response.data.study_instance_uid) {
          await this.getUrl(data.labpacsItem.id)
        }

      } catch (error) {
        console.error(error)
        this.$toast.warning('Resultado de exame ainda não disponível. Por favor tente novamente mais tarde ou entre em contato com o suporte.')
      } finally {
        isLoading.hide()
      }
    },
    async getUrl(labpacsItemId) {
        const response = await api.getUrlDicon(labpacsItemId)
        if (response.data) {
          this.$emit('reload')

          window.open(response.data.url, '_blank')
        } else {
          this.$toast.error('Resultado não disponível. Por favor tente novamente mais tarde.')
        }
    },
    openFileShowModal() {
      this.$bvModal.show('show-file-modal')
    },
    closeShowFileModal() {
      this.labpacsitem = null
      this.$bvModal.hide('show-file-modal')
    },
    async requestReport (data) {
      const isLoading = this.$loading.show()
      try {

       const payload = {
         clinic_procedure_id: data.item_id,
          accession_number: data.labpacsItem.accession_number,
          patient_id: data.patient.id
        }
        const response = this.api.labpacsRequestReport(payload)

        this.$toast.success(response.data.message)
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        this.$emit('reload')
        isLoading.hide()
    
      }
}

  }
}
</script>

<style lang="scss" scoped>
.patient-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: initial !important;
  justify-content: space-between;
}
.avatar {
  width: 50px !important;
}
.exam-date {
  font-size: 11px;
}

.age {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.table th,
.table td {
  padding: 0.75rem 0;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}
.tr-tbody {
  th {
    padding: 16px 8px;
  }
  .table {
    background-color: #ffffff !important;
  }

  .table th,
  .table td {
    padding: 0;
    vertical-align: middle;
    border-top: none;
  }

  td {
    padding: 16px 8px !important;
  }
}
.tr-header {
  th {
    padding: 16px 8px;
  }

  tr {
    background-color: #ffffff;
  }
  .table th,
  .table td {
    padding: 0;
    vertical-align: middle;
    border-top: none;
  }

  td {
    padding: 16px 8px !important;
  }
}

th,
td {
  text-align: left;
}

th {
  font-size: 14px;
  color: var(--dark-blue);
}
.td {
  padding: 0.75rem !important;
}
.procedure-column {
  max-width: 300px;
}

.clipboard-button {
  padding: 12px 16px;
  border-radius: 8px;
  background-color: var(--blue-100);
  border: none;

  &:hover {
    background-color: var(--neutral-300);
  }

  svg {
    stroke: var(--blue-500);
  }
}

.collapsable-row {
  td {
    padding: 0 !important;
    border: none !important;
  }
}
.toggle-btn {
  background: var(--blue-100);
  border: none;
  padding: 5px 8px !important;

  .icon {
    width: 20px;
    transition: all 500ms;
    stroke: var(--blue-500);
  }
}

.arrow {
  width: 14px;
  height: 14px;
}
.arrow {
  transition: rotate 1s;
}
.rotated {
  rotate: -180deg;
  transition: rotate 1s;
}
.actions {
  display: flex;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    cursor: pointer;
  }
}
.export {
  margin-left: 10px;
  cursor: pointer;
}
.not-finished-exams {
  color: orangered !important;
}
</style>
