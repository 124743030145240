var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasOwnerId)?_c('div',[_c('b-table-simple',{attrs:{"responsive":""}},[_c('thead',[_c('tr',{staticClass:"tr-header"},_vm._l((_vm.headers),function(header,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),_vm._l((_vm.examsList),function(exam){return _c('tbody',{key:exam.id},[_c('tr',{staticClass:"tr-tbody"},[_c('td',[_c('div',[_vm._v(" "+_vm._s(_vm.moment(exam?.start_datetime).format('HH[h]mm'))+" ")]),_c('div',{staticClass:"exam-date"},[_vm._v(" "+_vm._s(_vm.moment(exam?.start_datetime).format('DD/MM/YYYY'))+" ")])]),_c('td',[_c('div',{staticClass:"patient-container"},[_c('PatientInformation',{attrs:{"appointment":exam,"status":_vm.getExamsStatus(exam)}}),_c('Export',{directives:[{name:"can",rawName:"v-can",value:('FpPac1'),expression:"'FpPac1'"}],staticClass:"export",on:{"click":function($event){return _vm.goPatient(exam?.patient?.id)}}})],1)]),_c('td',[_c('Ellipsis',[_vm._v(" "+_vm._s(exam?.professional?.name ?? '-')+" ")])],1),_c('td',[_c('Ellipsis',[_vm._v(" "+_vm._s(exam?.room?.name ?? '-')+" ")])],1),_c('td',{staticClass:"procedure-column pending",class:{
          'not-finished-exams': _vm.getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED'
        }},[(_vm.getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED')?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.textualExamCounting( _vm.getAppointmentItemsCountingByStatus(exam, 'PENDING', 'EXAM') ))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.textualProcedureCounting( _vm.getAppointmentItemsCountingByStatus( exam, 'OPENED', 'PROCEDURE' ) ))+" ")])]):_c('span',[_vm._v("0")])]),_c('td',{staticClass:"procedure-column finished",class:{
          'not-finished-exams': _vm.getExamsStatus(exam) !== 'ALL_EXAMS_FINISHED'
        }},[(
            _vm.getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'EXAM') >
              0 ||
            _vm.getAppointmentItemsCountingByStatus(
              exam,
              'FINISHED',
              'PROCEDURE'
            ) > 0
          )?_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.textualExamCounting( _vm.getAppointmentItemsCountingByStatus(exam, 'FINISHED', 'EXAM') ))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.textualProcedureCounting( _vm.getAppointmentItemsCountingByStatus( exam, 'FINISHED', 'PROCEDURE' ) ))+" ")])]):_c('span',[_vm._v(" 0 ")])]),_c('td'),_c('td',[_c('div',{staticClass:"actions"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-${exam?.id}-${exam?.id}`),expression:"`collapse-${exam?.id}-${exam?.id}`"}],staticClass:"toggle-btn",on:{"click":function($event){return _vm.rotateIcon(exam?.id)}}},[_c('ChevronDown',{staticClass:"arrow",class:_vm.rotatedList.includes(exam?.id) ? 'rotated' : ''})],1)],1)])]),_c('tr',{staticClass:"collapsable-row"},[_c('td',{attrs:{"colspan":14}},[_c('b-collapse',{staticClass:"collapse",attrs:{"id":`collapse-${exam?.id}-${exam?.id}`}},[_c('SubLabpacsTable',{attrs:{"exam":exam},on:{"reload":() => _vm.$emit('reload'),"open-annotation-modal":_vm.handleOpenAnnotationModal,"open-show-file-modal":_vm.handleOpenShowFileModal,"request-report":_vm.requestReport}})],1)],1)])])})],2),_c('AnnotationModal',{ref:"annotationModal",attrs:{"labpacsItem":_vm.labpacsItemSelected},on:{"close":_vm.closeAnnotationModal}}),_c('ShowFileModal',{ref:"showFileModal",attrs:{"labpacsItem":_vm.labpacsItemSelected,"patientProp":_vm.patientSelected,"itemId":_vm.itemIdSelected},on:{"close":_vm.closeShowFileModal,"request-report":_vm.requestReport}})],1):(!_vm.examsList.length && !_vm.isLoading && _vm.hasOwnerId)?_c('NoFilterResults',{attrs:{"message":"Os filtros aplicados não geraram nenhum resultado"}}):(!_vm.isLoading && !_vm.hasOwnerId)?_c('NeedOwnerView'):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }